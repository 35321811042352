import styled from 'styled-components';

import { colors, metrics } from 'styles';

export const Container = styled.div`
  max-width: ${metrics.container_max_width};
  width: 100%;
  border-radius: 1.5rem;
  padding: 1.2rem;
  max-width: 100%;
  margin: 0 auto;

  img {
    width: 100%;
    max-width: 100%;
    max-width: max-content;
    cursor: pointer;
  }

  .slick-next,
  .slick-prev {
    width: inherit;
    height: inherit;
  }

  .slick-prev:before,
  .slick-next:before {
    color: #fff;
    opacity: 1;
  }

  .slick-slide {
    max-width: max-content;
    width: 100%;
  }

  .slick-track {
    display: flex;
    justify-content: space-between;
  }

  .slick-slide + .slick-slide {
    margin-left: 1.2rem;
  }
`;

interface IItemProps {
  imgCount: number;
}

export const Item = styled.div<IItemProps>`
  min-height: 5.6rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  /* & + div {
    margin-left: 1.2rem;
  } */

  a {
    width: 100%;
    img {
      margin: 0 auto;
    }
  }
`;
