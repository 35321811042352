import React, { useEffect, useState } from 'react';

import Image from 'next/image';
import Link from 'next/link';
import Slider, { Settings } from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Container, Item } from './styles';

interface ISimpleCarouselProps {
  items: Array<{
    campanha: string;
    imagemMobileUrl: string;
    imagemUrl: string;
    ordem: number;
    redirecionarUrl: string;
    titulo: string;
  }>;
}

const SimpleCarousel: React.FC<ISimpleCarouselProps> = ({ items }) => {
  const [windowWidth, setWindowWidth] = useState(false);

  const settings: Settings = {
    infinite: true,
    speed: 500,
    slidesToShow: items.length,
    centerPadding: '60px',
    slidesToScroll: items.length,
    arrows: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: items.length === 1 ? 1 : items.length - 1,
          slidesToScroll: items.length === 1 ? 1 : items.length - 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: items.length === 1 ? 1 : items.length - 2,
          slidesToScroll: items.length === 1 ? 1 : items.length - 2,
        },
      },
    ],
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.innerWidth > 768) {
        setWindowWidth(true);
      }
    }
  }, []);

  return (
    <Container>
      <Slider {...settings}>
        {items.map((item, index) => (
          <Item data-index={index} key={item.imagemUrl} imgCount={items.length}>
            <Link href={`${item.redirecionarUrl || '/'}`} passHref>
              <a href="replaced">
                <img
                  src={windowWidth ? item.imagemUrl : item.imagemMobileUrl}
                  alt={item.titulo}
                  title={item.titulo}
                />
              </a>
            </Link>
          </Item>
        ))}
      </Slider>
    </Container>
  );
};

export default SimpleCarousel;
